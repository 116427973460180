import React from "react";

const Footer = () => {
  return (
    <footer className="bg-indigo-800 text-white py-5 mt-4 rounded-t-xl ">
      <div className="container mx-auto text-center">
        <p className="text-sm">
          Designed with ❤️ and crafted with care by{" "}
          <a
            href="https://www.youtube.com/@SHALENDERSINGH"
            target="_blank"
            rel="noopener noreferrer"
            className="text-indigo-300 hover:text-white"
          >
            Team SMART ITBOX
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
