import React, { useState, useEffect } from "react";
import Sidebar from "../categories/Sidebar"; // Import Sidebar Component
import Header from "../categories/Header"; // Import Header Component
import Dashboard from "./Dashboard";
import Footer from "../Authentication/Footer";

const MainDashboard = () => {
  const [isSidebarClosed, setSidebarClosed] = useState(false);
  const [stats, setStats] = useState({ users: 0, clients: 0, quotations: 0 });

  // Toggle sidebar visibility
  const toggleSidebar = () => {
    setSidebarClosed((prev) => !prev);
  };

  // Fetch stats data from API
  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/quotations/quotation-stats`
        );
        const data = await response.json();
        setStats({
          users: data.totalUsers || 0,
          clients: data.totalClients || 0,
          quotations: data.totalQuotations || 0,
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchStats();
  }, []);

  return (
    <div className="flex flex-col md:flex-row h-screen bg-gray-50 ">
      {/* Sidebar */}
      <Sidebar
        isSidebarClosed={isSidebarClosed}
        toggleSidebar={toggleSidebar}
      />

      {/* Main Content */}
      <div
        className={`flex-1 flex flex-col transition-all duration-300 ${
          isSidebarClosed ? "ml-0" : "ml-64"
        }`}
      >
        {/* Header */}
        <Header toggleSidebar={toggleSidebar} />

        {/* Content Section */}
        <main className="flex-1  md:p-8 overflow-auto">
          {/* Pass stats to Dashboard */}
          <Dashboard stats={stats} />
          
          ;
        </main>
       {/* <Footer></Footer> */}
      </div>
    </div>
  );
};

export default MainDashboard;
