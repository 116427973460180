import React, { useState } from "react";
import Sidebar from "../categories/Sidebar"; // Import Sidebar Component
import Header from "../categories/Header"; // Import Header Component
import UsersTable from "./UsersTable";
import Footer from "../Authentication/Footer";

const UserData = () => {
  const [isSidebarClosed, setIsSidebarClosed] = useState(false);

  // Toggle sidebar visibility
  const toggleSidebar = () => setIsSidebarClosed(!isSidebarClosed);

  return (
    <div className="flex h-screen bg-gray-50">
      {/* Sidebar */}
      <div
        className={`${
          isSidebarClosed ? "w-16" : "w-64"
        } transition-width duration-300 ease-in-out`}
      >
        <Sidebar
          isSidebarClosed={isSidebarClosed}
          toggleSidebar={toggleSidebar}
        />
      </div>

      {/* Main Content */}
      <div className="flex flex-col flex-1 min-h-0">
        {/* Header */}
        <div className="flex-none">
          <Header toggleSidebar={toggleSidebar} />
        </div>

        {/* Content Section */}
        <main className="flex-1 p-4 overflow-auto">
          <UsersTable />
        </main>

        {/* Footer */}
        <div className="flex-none">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default UserData;
