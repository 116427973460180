import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  HiOutlineShoppingBag,
  HiOutlineHome,
  HiOutlineLogout,
  HiOutlineUser,
  HiOutlineX,
  HiOutlineMenu,
  HiOutlineDocument,
} from "react-icons/hi";
import axios from "axios";
import { API_BASE_URL } from "../../config";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Sidebar = ({ isSidebarClosed, toggleSidebar }) => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await axios.post(
        `${API_BASE_URL}/api/auth/logout`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      localStorage.removeItem("token");
      localStorage.removeItem("role");

      toast.success("Successfully logged out!", { autoClose: 3000 });

      navigate("/");
    } catch (error) {
      console.error("Logout error:", error);
      toast.error("An error occurred during logout. Please try again.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  return (
    <>
      {/* Sidebar */}
      <div
        className={`${
          isSidebarClosed ? "hidden" : "w-64"
        } bg-blue-800 text-white h-full p-5 transition-all duration-300 ease-in-out fixed z-10 flex flex-col`}
      >
        {/* Header Section */}
        <div className="flex justify-between items-center">
          <span className="text-lg font-bold">Admin Dashboard</span>
          {/* Close Button */}
          <button
            className="text-white text-2xl"
            onClick={toggleSidebar}
            aria-label="Close Sidebar"
          >
            <HiOutlineX />
          </button>
        </div>

        {/* Sidebar Links */}
        <nav className="mt-10">
          <ul>
            <li className="mb-4">
              <Link
                to="/admin-dashboard"
                className="flex items-center text-white hover:bg-indigo-600 rounded p-2 transition-all"
              >
                <HiOutlineHome className="inline-block mr-2" />
                <span>Dashboard</span>
              </Link>
            </li>
            <li className="mb-4">
              <Link
                to="/categories"
                className="flex items-center text-white hover:bg-indigo-600 rounded p-2 transition-all"
              >
                <HiOutlineShoppingBag className="inline-block mr-2" />
                <span>Item Master</span>
              </Link>
            </li>
            <li className="mb-4">
              <Link
                to="/user-details"
                className="flex items-center text-white hover:bg-indigo-600 rounded p-2 transition-all"
              >
                <HiOutlineUser className="inline-block mr-2" />
                <span>User Details</span>
              </Link>
            </li>
            <li className="mb-4">
              <Link
                to="/client-details"
                className="flex items-center text-white hover:bg-indigo-600 rounded p-2 transition-all"
              >
                <HiOutlineUser className="inline-block mr-2" />
                <span>Client Details</span>
              </Link>
            </li>
            <li className="mb-4">
              <Link
                to="/quotation-create"
                className="flex items-center text-white hover:bg-indigo-600 rounded p-2 transition-all"
              >
                <HiOutlineDocument className="inline-block mr-2" />
                <span>Create Quotation</span>
              </Link>
            </li>
            <li className="mb-4 mt-6">
              <button
                onClick={handleLogout}
                className="flex items-center text-white bg-red-500 hover:bg-red-700 rounded p-2 transition-all w-full"
              >
                <HiOutlineLogout className="inline-block mr-2" />
                <span>Logout</span>
              </button>
            </li>
          </ul>
        </nav>
      </div>

      {/* Open Button */}
      {isSidebarClosed && (
        <button
          className="fixed top-4 left-4 z-20 text-white bg-blue-800 p-2 rounded-full shadow-lg"
          onClick={toggleSidebar}
          aria-label="Open Sidebar"
        >
          <HiOutlineMenu className="text-2xl" />
        </button>
      )}

      <ToastContainer />
    </>
  );
};

export default Sidebar;
