// src/components/Card.jsx
import React from "react";

const Card = ({ title, count, icon, color = "bg-blue-400" }) => {
  return (
    <div
      className={`p-6 max-w-sm w-full ${color} rounded-lg shadow-lg shadow-blue-400 text-white transform transition-transform hover:scale-105 justify-items-center`}
    >
      <div className="flex items-center justify-between justify-items-center">
        <div>
          <h3 className="text-lg font-semibold">{title}</h3>
          <p className="text-3xl font-bold">{count}</p>
        </div>
        <div className="text-4xl">{icon}</div>
      </div>
    </div>
  );
};

export default Card;
