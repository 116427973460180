import React, { useState } from "react";
import Sidebar from "../categories/Sidebar"; // Import Sidebar Component
import Header from "../categories/Header"; // Import Header Component
import QuotationTable from "./QuotationTable";
import Footer from "../Authentication/Footer"; // Import the Footer component

const QuotationPage = () => {
  const [isSidebarClosed, setIsSidebarClosed] = useState(false);

  // Toggle sidebar visibility
  const toggleSidebar = () => setIsSidebarClosed(!isSidebarClosed);

  return (
    <>
      <div className="flex flex-col md:flex-row h-screen bg-gray-50">
        {/* Sidebar */}
        <Sidebar
          isSidebarClosed={isSidebarClosed}
          toggleSidebar={toggleSidebar}
        />

        {/* Main Content */}
        <div
          className={`flex-1 flex flex-col transition-all duration-300 ${
            isSidebarClosed ? "md:ml-16" : "md:ml-64"
          }`}
        >
          {/* Header */}
          <Header toggleSidebar={toggleSidebar} />

          {/* Content Section */}
          <main className="flex-1 p-4 md:p-8 overflow-auto">
            <QuotationTable />
          </main>

          {/* Footer */}
          <Footer />
        </div>
      </div>
    </>
  );
};

export default QuotationPage;
