import React, { useState, useEffect } from "react";
import Card from "./Card";
import QuotationsChart from "./QuotationsChart";
import QuotationsPieChart from "./QuotationsPieChart"; // Import the pie chart component
import { FaUsers, FaRegAddressCard, FaClipboardList } from "react-icons/fa";
import { API_BASE_URL } from "../../config";

const Dashboard = () => {
  const [stats, setStats] = useState({ users: 0, clients: 0, quotations: 0 });
  const [userQuotationsData, setUserQuotationsData] = useState({});
  const [totalAmount, setTotalAmount] = useState(0); // State for total amount
  const [view, setView] = useState("daily"); // Default view is 'daily'

  useEffect(() => {
    // Fetch live data from the API only once
    fetch(`${API_BASE_URL}/api/quotations/quotation-stats`)
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.quotationStats)) {
          const totalQuotations = data.quotationStats.reduce(
            (acc, item) => acc + item.totalQuotations,
            0
          );
          const totalClients = data.totalClients;
          const totalUsers = data.totalUsers;

          // Calculate total amount
          const totalAmount = data.quotationStats.reduce(
            (acc, item) => acc + item.totalAmount,
            0
          );

          // Process quotations data
          const processedData = processQuotationsData(data.quotationStats);

          const processedStats = {
            users: totalUsers,
            clients: totalClients,
            quotations: totalQuotations,
          };

          setStats(processedStats);
          setUserQuotationsData(processedData);
          setTotalAmount(totalAmount); // Set the total amount
        } else {
          console.error("Invalid data format:", data);
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const processQuotationsData = (users) => {
    const daily = {};
    const weekly = {};
    const monthly = {};
    const valueWise = {}; // Adding data structure for value-wise view

    const allUserNames = users.map((user) => user.fullName);

    users.forEach((user) => {
      const userName = user.fullName;

      user.quotations.forEach((quotation) => {
        const { createdAt, value } = quotation; // Assuming 'value' is a property
        const date = new Date(createdAt);

        const day = date.toISOString().split("T")[0];
        const week = `${date.getFullYear()}-W${Math.ceil(date.getDate() / 7)}`;
        const month = `${date.getFullYear()}-${date.getMonth() + 1}`;

        if (!daily[day]) daily[day] = {};
        daily[day][userName] = (daily[day][userName] || 0) + 1;

        if (!weekly[week]) weekly[week] = {};
        weekly[week][userName] = (weekly[week][userName] || 0) + 1;

        if (!monthly[month]) monthly[month] = {};
        monthly[month][userName] = (monthly[month][userName] || 0) + 1;

        // Process value-wise data
        if (!valueWise[userName]) valueWise[userName] = 0;
        valueWise[userName] += value; // Summing up quotation values for each user
      });
    });

    Object.keys(daily).forEach((day) => {
      allUserNames.forEach((userName) => {
        if (!daily[day][userName]) {
          daily[day][userName] = 0;
        }
      });
    });

    return { daily, weekly, monthly, valueWise };
  };

  const handleToggle = (viewType) => {
    setView(viewType);
  };

  return (
    <div className=" flex flex-col items-center  p-2 mt-0 space-y-8 justify-items-center ">
      <div className="p-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 ">
        <Card
          title="Users"
          count={stats.users}
          icon={<FaUsers />}
          color="bg-green-400"
        />

        <Card
          title="Clients"
          count={stats.clients}
          icon={<FaRegAddressCard />}
          color="bg-indigo-400"
        />

        <Card
          title="Quotations"
          count={stats.quotations}
          icon={<FaClipboardList />}
          color="bg-yellow-400"
        />

        <Card
          title={<div className="text-center">Total Amount</div>}
          count={`₹${(totalAmount / 10000000).toFixed(4)} Cr`}
          // Format total amount
          icon={<FaClipboardList />}
          color="bg-blue-400"
        />
      </div>

      <div className="flex space-x-10 mb-10">
        <button
          className={`p-2 rounded-md ${
            view === "daily" ? "bg-blue-500 text-white" : "bg-gray-200"
          }`}
          onClick={() => handleToggle("daily")}
        >
          Day-wise
        </button>
        <button
          className={`p-2 rounded-md ${
            view === "weekly" ? "bg-blue-500 text-white" : "bg-gray-200"
          }`}
          onClick={() => handleToggle("weekly")}
        >
          Week-wise
        </button>
        <button
          className={`p-2 rounded-md ${
            view === "monthly" ? "bg-blue-500 text-white" : "bg-gray-200"
          }`}
          onClick={() => handleToggle("monthly")}
        >
          Month-wise
        </button>
      </div>

      <QuotationsChart data={userQuotationsData[view]} />

      {/* Pie Chart */}
      <QuotationsPieChart data={userQuotationsData[view]} />
    </div>
  );
};

export default Dashboard;
