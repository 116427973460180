import React from "react";
import { Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(CategoryScale, LinearScale, ArcElement, Tooltip, Legend);

const QuotationsPieChart = ({ data }) => {
  // Safety check to ensure data is valid
  if (!data || Object.keys(data).length === 0) {
    return <div>No data available</div>;
  }

  // Helper function to process data into chart format
  const processChartData = (data) => {
    const userQuotations = {};
    const labels = Object.keys(data);

    // Calculate total quotations per user
    labels.forEach((label) => {
      const users = Object.keys(data[label]);
      users.forEach((user) => {
        userQuotations[user] = (userQuotations[user] || 0) + data[label][user];
      });
    });

    // Extract users and their totals
    const userNames = Object.keys(userQuotations);
    const userValues = userNames.map((name) => userQuotations[name]);

    // Define colors
    const colors = [
      "rgba(255, 99, 132, 0.6)",
      "rgba(54, 162, 235, 0.6)",
      "rgba(75, 192, 192, 0.6)",
      "rgba(153, 102, 255, 0.6)",
      "rgba(255, 159, 64, 0.6)",
      "rgba(255, 205, 86, 0.6)",
      "rgba(255, 99, 71, 0.6)",
      "rgba(255, 182, 193, 0.6)",
      "rgba(173, 216, 230, 0.6)",
      "rgba(144, 238, 144, 0.6)",
    ];

    return {
      labels: userNames,
      datasets: [
        {
          data: userValues,
          backgroundColor: colors.slice(0, userNames.length),
          borderColor: colors
            .slice(0, userNames.length)
            .map((color) => color.replace("0.6", "1")),
          borderWidth: 1,
        },
      ],
    };
  };

  const chartData = processChartData(data);

  const options = {
    responsive: true,
    maintainAspectRatio: false, // Disable maintaining the default aspect ratio
    plugins: {
      title: {
        display: true,
        text: "User Quotations Distribution",
        font: {
          size: 20,
        },
      },
      legend: {
        position: "top",
      },
    },
  };

  return (
    <div
      className="w-full max-w-5xl p-6 bg-white rounded-lg shadow-lg shadow-gray-600 overflow-x-auto"
      style={{ height: "400px", maxHeight: "400px" }} // Adjust container height
    >
      <Pie data={chartData} options={options} />
    </div>
  );
};

export default QuotationsPieChart;
