import React, { useState, useEffect } from "react";
import { HiOutlineMenuAlt2 } from "react-icons/hi"; // Hamburger icon
import { HiUserCircle, HiShieldCheck } from "react-icons/hi"; // User and Admin icons
import axios from "axios";
import logo from "./../../images/logo3.png";
import { API_BASE_URL } from "../../config";

const Header = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (token) {
          const response = await axios.get(`${API_BASE_URL}/api/auth/me`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          setUserData(response.data);
        } else {
          console.warn("No token found, redirecting to login...");
          window.location.href = "/login";
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  if (loading) return <div className="text-center">Loading...</div>;
  if (!userData)
    return <div className="text-center">User data not available</div>;

  return (
    <div className="relative">
      {/* Sidebar */}
      <div
        className={`fixed top-0 left-0 h-full w-64 bg-gray-800 text-white transform ${
          sidebarOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out z-50`}
      >
        <button
          className="absolute top-4 right-4 text-white"
          onClick={() => setSidebarOpen(false)}
        >
          Close
        </button>
        <ul className="p-4">
          <li className="mb-4">Dashboard</li>
          <li className="mb-4">Profile</li>
          <li className="mb-4">Settings</li>
        </ul>
      </div>

      {/* Header */}
      <header
        className={`bg-gray-200 text-white p-5 flex justify-between items-center shadow-lg transition-all duration-300 ${
          sidebarOpen ? "ml-64" : "ml-0"
        }`}
      >
        <button
          className="md:hidden text-2xl"
          onClick={() => setSidebarOpen(!sidebarOpen)}
          aria-label="Toggle Sidebar"
        >
          <HiOutlineMenuAlt2 />
        </button>

        <div className="flex items-center space-x-4 pl-[2rem]">
          <img src={logo} alt="Logo" className="h-12 object-contain" />
        </div>

        <div className="flex items-center space-x-4">
          {userData.role === "admin" ? (
            <HiShieldCheck
              className="text-yellow-500 text-3xl"
              aria-label="Admin Icon"
            />
          ) : (
            <HiUserCircle
              className="text-blue-500 text-3xl"
              aria-label="User Icon"
            />
          )}

          <div className="flex flex-col items-end">
            <span className="text-sm font-semibold text-black">
              {userData.fullName}
            </span>
            <span
              className={`text-xs font-semibold ${
                userData.role === "admin" ? "text-red-500" : "text-blue-500"
              }`}
            >
              {userData.role}
            </span>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
