import React from 'react';
import * as XLSX from 'xlsx';
import * as XlsxPopulate from 'xlsx-populate/browser/xlsx-populate'; // For advanced styling

const ExportToExcel = ({ quotations }) => {
  // Function to create and download Excel file
  const exportToExcel = () => {
    // Group quotations by quotationIdNumber
    const groupedQuotations = groupQuotationsById(quotations);

    // Prepare the data for Excel export
    const worksheetData = [
      ['Quotation ID', 'Created At (IST)', 'Created By', 'Client Name', 'Amount (₹)', 'Status'],
      ...groupedQuotations.map((group) => {
        // If there's more than one quotation in the group, find the latest one
        if (group.length > 1) {
          const latestQuotation = group.reduce((latest, q) =>
            new Date(q.createdAt) > new Date(latest.createdAt) ? q : latest, group[0]);

          return group.map((quotation) => [
            quotation.quotationIdNumber,
            formatDateToIST(quotation.createdAt),
            quotation.createdBy?.fullName || 'Unknown',
            quotation.client?.clientName || 'Unknown',
            quotation.totalAfterGST.toLocaleString(),
            quotation._id === latestQuotation._id ? 'NEW' : '', // Mark "NEW" only for the latest in the group
          ]);
        } else {
          // If there's only one quotation, don't mark it as "NEW"
          return group.map((quotation) => [
            quotation.quotationIdNumber,
            formatDateToIST(quotation.createdAt),
            quotation.createdBy?.fullName || 'Unknown',
            quotation.client?.clientName || 'Unknown',
            quotation.totalAfterGST.toLocaleString(),
            '', // No "NEW" keyword for single quotation
          ]);
        }
      }).flat(), // Flatten the nested array of grouped quotations
    ];

    // Create the workbook
    const wb = XLSX.utils.book_new();
    const sheet = XLSX.utils.aoa_to_sheet(worksheetData);
    XLSX.utils.book_append_sheet(wb, sheet, 'Quotations');

    // Convert workbook to blob
    const workbookBlob = workbook2blob(wb);

    // Add styling and export
    addStyle(workbookBlob).then((url) => {
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", url);
      downloadAnchorNode.setAttribute("download", "Quotations.xlsx");
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    });
  };

  // Group quotations by quotationIdNumber
  const groupQuotationsById = (quotations) => {
    return Object.values(quotations.reduce((acc, quotation) => {
      // Use the quotationIdNumber as the key for grouping
      if (!acc[quotation.quotationIdNumber]) {
        acc[quotation.quotationIdNumber] = [];
      }
      acc[quotation.quotationIdNumber].push(quotation);
      return acc;
    }, {}));
  };

  // Convert workbook to Blob
  const workbook2blob = (workbook) => {
    const wopts = { bookType: "xlsx", bookSST: false, type: "binary" };
    const wbout = XLSX.write(workbook, wopts);
    const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });
    return blob;
  };

  // Convert string to ArrayBuffer
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i);
    }
    return buf;
  };

 

// Add styles using XlsxPopulate
const addStyle = (workbookBlob) => {
  return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
    const sheet = workbook.sheet(0);

    // Style the entire used range
    sheet.usedRange().style({
      fontFamily: "Arial",
      verticalAlignment: "center",
    });

    // Set column widths
    sheet.column("A").width(20);
    sheet.column("B").width(30);
    sheet.column("C").width(25);
    sheet.column("D").width(40);
    sheet.column("E").width(15);
    sheet.column("F").width(10); // For status column

    // Apply header styles
    sheet.range("A1:F1").style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      fill: "4F81BD", // Blue background
      fontColor: "FFFFFF", // White text color
    });

    // Highlight cells that have "NEW" in the "Status" column (F)
    sheet.usedRange().forEach((cell) => {
      if (cell.value() === "NEW") {
        // Apply a distinct background color and font color for "NEW"
        cell.style({
          fill: "FFEB3B", // Yellow background
          fontColor: "FF0000", // Red text color
          bold: true, // Make the text bold
        });
      }
    });

    // Return the workbook as a URL for download
    return workbook.outputAsync().then((workbookBlob) => URL.createObjectURL(workbookBlob));
  });
};



  // Format the date to IST
  const formatDateToIST = (dateString) => {
    const date = new Date(dateString);
    const options = {
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
      timeZone: 'Asia/Kolkata',
    };
    return date.toLocaleString('en-IN', options);
  };

  return (
    <button
      onClick={exportToExcel}
      className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-700"
    >
      Export to Excel
    </button>
  );
};

export default ExportToExcel;




// highlighting new text





// const addStyle = (workbookBlob) => {
//   return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
//     const sheet = workbook.sheet(0);

//     // Style the entire used range
//     sheet.usedRange().style({
//       fontFamily: "Arial",
//       verticalAlignment: "center",
//     });

//     // Set column widths
//     sheet.column("A").width(20);
//     sheet.column("B").width(30);
//     sheet.column("C").width(25);
//     sheet.column("D").width(40);
//     sheet.column("E").width(15);
//     sheet.column("F").width(10); // For status column

//     // Apply header styles
//     sheet.range("A1:F1").style({
//       bold: true,
//       horizontalAlignment: "center",
//       verticalAlignment: "center",
//       fill: "4F81BD", // Blue background
//       fontColor: "FFFFFF", // White text color
//     });

//     // Highlight entire rows that have "NEW" in the "Status" column (F)
//     sheet.usedRange().forEach((cell, rowIndex) => {
//       if (cell.value() === "NEW") {
//         // Highlight the entire row with yellow background and red text
//         sheet.range(`A${rowIndex + 1}:F${rowIndex + 1}`).style({
//           fill: "FFEB3B", // Yellow background
//           fontColor: "FF0000", // Red text color
//           bold: true, // Make the text bold
//         });
//       }
//     });

//     // Return the workbook as a URL for download
//     return workbook.outputAsync().then((workbookBlob) => URL.createObjectURL(workbookBlob));
//   });
// };