import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const QuotationsChart = ({ data, maxUsers = 10 }) => {
  const [chartMode, setChartMode] = useState("userWise"); // Track the mode of the chart
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Update `isMobile` on window resize
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (!data || Object.keys(data).length === 0) {
    return <div>No data available</div>;
  }

  // Function to process data based on chartMode
  const processChartData = (data) => {
    const labels = Object.keys(data);
    if (labels.length === 0) {
      return { labels: [], datasets: [] };
    }

    const userQuotations = {};
    const allUserNames = Object.keys(data[labels[0]]);

    // Calculate total quotations or value for each user
    allUserNames.forEach((userName) => {
      let total = 0;
      labels.forEach((label) => {
        total += data[label][userName] || 0; // Accumulate total for each user
      });
      if (total > 0) {
        userQuotations[userName] = total;
      }
    });

    // Sort users based on their total values
    const sortedUsers = Object.keys(userQuotations)
      .sort((a, b) => userQuotations[b] - userQuotations[a])
      .slice(0, maxUsers);

    const colors = [
      "rgba(255, 99, 132, 0.6)",
      "rgba(54, 162, 235, 0.6)",
      "rgba(75, 192, 192, 0.6)",
      "rgba(153, 102, 255, 0.6)",
      "rgba(255, 159, 64, 0.6)",
      "rgba(255, 205, 86, 0.6)",
      "rgba(255, 99, 71, 0.6)",
      "rgba(255, 182, 193, 0.6)",
      "rgba(173, 216, 230, 0.6)",
      "rgba(144, 238, 144, 0.6)",
    ];

    // Prepare datasets based on the mode
    if (chartMode === "valueWise") {
      return {
        labels: sortedUsers,
        datasets: [
          {
            label: "Total Value of Quotations",
            data: sortedUsers.map((userName) => userQuotations[userName]),
            backgroundColor: colors.slice(0, sortedUsers.length),
            borderColor: colors
              .slice(0, sortedUsers.length)
              .map((color) => color.replace("0.6", "1")),
            borderWidth: 1,
            barThickness: isMobile ? 10 : 20, // Adjust bar thickness for mobile
            maxBarThickness: isMobile ? 15 : 30, // Limit bar size for mobile
          },
        ],
      };
    }

    // User-Wise mode
    const datasets = sortedUsers.map((userName, index) => {
      const userData = labels.map((label) => data[label][userName] || 0);
      return {
        label: userName,
        data: userData,
        backgroundColor: colors[index % colors.length],
        borderColor: colors[index % colors.length].replace("0.6", "1"),
        borderWidth: 1,
      };
    });

    return { labels, datasets };
  };

  const chartData = processChartData(data);

  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text:
          chartMode === "valueWise"
            ? "Total Value of Quotations Per User"
            : "Quotations Generated by User",
        font: {
          size: isMobile ? 16 : 20, // Adjust title font size for mobile
        },
      },
      legend: {
        position: "top",
        labels: {
          font: {
            size: isMobile ? 10 : 14, // Adjust legend font size for mobile
          },
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          autoSkip: true,
          font: {
            size: isMobile ? 10 : 14, // Adjust x-axis font size for mobile
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          font: {
            size: isMobile ? 10 : 14, // Adjust y-axis font size for mobile
          },
        },
      },
    },
  };

  return (
    <div className="w-full max-w-5xl p-6 bg-white rounded-lg shadow-lg shadow-gray-600 overflow-x-auto">
      <div className="flex justify-end mb-4">
        {/* Toggle Buttons */}
        <button
          onClick={() => setChartMode("userWise")}
          className={`px-6 py-3 rounded-l-lg transition duration-300 ease-in-out transform ${
            chartMode === "userWise"
              ? "bg-blue-600 text-white shadow-lg scale-105"
              : "bg-gray-200 text-gray-600 hover:bg-gray-300"
          }`}
        >
          User Wise
        </button>
        <button
          onClick={() => setChartMode("valueWise")}
          className={`px-6 py-3 rounded-r-lg transition duration-300 ease-in-out transform ${
            chartMode === "valueWise"
              ? "bg-blue-600 text-white shadow-lg scale-105"
              : "bg-gray-200 text-gray-600 hover:bg-gray-300"
          }`}
        >
          Value Wise
        </button>
      </div>
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default QuotationsChart;
