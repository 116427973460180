import React, { useState, useEffect } from "react";
import { HiOutlinePencil, HiOutlineTrash } from "react-icons/hi";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; 
import EditModal from "./EditModal"; // Assuming EditModal is in the same folder
import { API_BASE_URL } from "../../config";
const CategoriesTable = () => {
  const [categories, setCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // State for controlling modal visibility
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    setLoading(true);
    setError(null);

    try {
      const token = localStorage.getItem("token");
      const response = await fetch(`${API_BASE_URL}/api/categories`, {
        headers: {
          Authorization: `Bearer ${token}`, // Replace with your auth token if required
        },
      });
      const data = await response.json();

      if (data.success) {
        setCategories(data.categories);
      } else {
        setError("Failed to fetch categories");
      }
    } catch (error) {
      setError("An error occurred while fetching categories");
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to the first page when search term changes
  };

  // Apply filtering based on search term
  const filteredCategories = searchTerm
    ? categories.filter((category) => {
        const lowercasedSearchTerm = searchTerm.toLowerCase();

        // First, check if itemName matches (highest priority)
        if (category.itemName.toLowerCase().includes(lowercasedSearchTerm)) {
          return true;
        }

        // If not found, then check categoryName
        if (category.categoryName.toLowerCase().includes(lowercasedSearchTerm)) {
          return true;
        }

        // Finally, check specification if nothing else matches
        return category.specification.toLowerCase().includes(lowercasedSearchTerm);
      })
    : categories; // If no search term, return all categories

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredCategories.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredCategories.length / itemsPerPage);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleEdit = (category) => {
    setSelectedCategory(category);
    setIsModalOpen(true);
  };

  // Handle modal submit (update category)
  const handleUpdateCategory = (updatedCategory) => {
    setCategories((prevCategories) =>
      prevCategories.map((category) =>
        category.subtypeId === updatedCategory.subtypeId ? updatedCategory : category
      )
    );
    setIsModalOpen(false); // Close the modal
  };

  const handleDelete = async (categoryName, subtypeId) => {
    if (!subtypeId) {
      toast.error("Subtype ID is missing.");
      return;
    }

    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${API_BASE_URL}/api/categories/${categoryName}/subtypes/${subtypeId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`, // Replace with your auth token if required
          },
        }
      );
      const data = await response.json();

      if (data.success) {
        setCategories((prevCategories) =>
          prevCategories.filter(
            (category) => category.subtypeId !== subtypeId
          )
        );
        toast.success("Subtype deleted successfully!");
        fetchCategories();
      } else {
        toast.error("Failed to delete subtype.");
      }
    } catch (error) {
      toast.error("An error occurred while deleting the subtype.");
    }
  };

  const handleDeleteAll = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(`${API_BASE_URL}/api/categories/deleteAll`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();

      if (data.success) {
        setCategories([]); // Clear the categories from the state
        toast.success("All categories deleted successfully!");
      } else {
        toast.error("Failed to delete all categories.");
      }
    } catch (error) {
      toast.error("An error occurred while deleting all categories.");
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedCategory(null);
  };

  return (
    <div className="overflow-x-auto bg-white rounded-lg shadow-lg">
      <div className="p-4">
        {/* Search Input */}
        <div className="mb-4">
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearch}
            placeholder="Search Categories..."
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
        {/* Delete All Button */}
        <div className="mb-4 flex justify-end">
          <button
            onClick={handleDeleteAll}
            className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-700"
          >
            Delete All
          </button>
        </div>

        {loading ? (
          <div className="text-center py-4">
            <p>Loading categories...</p>
          </div>
        ) : error ? (
          <div className="text-center py-4 text-red-500">
            <p>{error}</p>
          </div>
        ) : (
          <div className="overflow-x-auto">
            {/* Table */}
            <table className="min-w-full table-auto">
              <thead className="bg-gray-100">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Category</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Item Name</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Image</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Specification</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Price</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {currentItems.map((category) => (
                  <tr key={category.itemName} className="hover:bg-gray-50">
                    <td className="px-6 py-4 text-sm">{category.categoryName}</td>
                    <td className="px-6 py-4 text-sm">{category.itemName}</td>
                    <td className="px-6 py-4 text-sm">
                      {category.itemImage ? (
                        <img
                          src={category.itemImage}
                          alt={"No image available"}
                          className="w-16 h-16 object-cover rounded-full"
                        />
                      ) : (
                        <span>No image available</span>
                      )}
                    </td>
                    <td className="px-6 py-4 text-sm">
                      <textarea
                        value={category.specification}
                        readOnly
                        rows={2}
                        className="w-full p-2 border border-gray-300 rounded-md bg-gray-200 cursor-not-allowed opacity-90 focus:outline-none focus:ring-2 focus:ring-indigo-600"
                      />
                    </td>
                    <td className="px-6 py-4 text-sm">{category.price}</td>
                    <td className="px-6 py-4 text-sm flex space-x-2">
                      <button
                        onClick={() => handleEdit(category)}
                        className="text-blue-500 hover:text-blue-700"
                      >
                        <HiOutlinePencil size={20} />
                      </button>
                      <button
                        onClick={() => handleDelete(category.categoryName, category.subtypeId)}
                        className="text-red-500 hover:text-red-700"
                      >
                        <HiOutlineTrash size={20} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {/* Pagination */}
        <div className="mt-4 flex flex-col sm:flex-row justify-between items-center">
          <div className="mb-4 sm:mb-0">
            <span className="text-sm text-gray-500">
              Showing {indexOfFirstItem + 1} to {Math.min(indexOfLastItem, categories.length)} of {categories.length} categories
            </span>
          </div>

          <div className="flex space-x-2">
            <button
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
              className="px-3 py-1 bg-gray-200 text-gray-600 rounded-lg disabled:bg-gray-400"
            >
              Prev
            </button>
            <button
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="px-3 py-1 bg-gray-200 text-gray-600 rounded-lg disabled:bg-gray-400"
            >
              Next
            </button>
          </div>
        </div>
      </div>

      {/* Toast Container for displaying toast messages */}
      <ToastContainer />

      {/* Conditionally render the EditModal */}
      {isModalOpen && selectedCategory && (
        <EditModal
          categoryName={selectedCategory.categoryName}
          subtypeId={selectedCategory.subtypeId}
          initialData={{
            itemName: selectedCategory.itemName,
            specification: selectedCategory.specification,
            price: selectedCategory.price,
            itemImage: selectedCategory.itemImage,
          }}
          onClose={closeModal}
          onSubmit={() => handleUpdateCategory(selectedCategory)}
        />
      )}
    </div>
  );
};

export default CategoriesTable;

